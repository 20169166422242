<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <div class="mt-10" v-if="sop.length === 0">
      <v-alert type="error">
        Belum Ada <strong>SOP</strong> Untuk Saat Ini
      </v-alert>
    </div>

    <div class="mt-10 pb-10" v-else>
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="item in sop"
          :key="item.sop_pengaduan_id"
        >
          <v-card
            outlined
            height="400"
            style="overflow-y: scroll"
            @click="showIMG(item.sop_pengaduan_foto)"
          >
            <div>
              <v-img :src="getIMG(item.sop_pengaduan_foto)"></v-img>
            </div>
          </v-card>
          <div class="mt-5">
            <h3>{{ item.sop_pengaduan_nama }}</h3>
          </div>
          <div class="mt-2">
            <span class="subtitle-2 grey--text">{{
              item.sop_pengaduan_ket
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Data",
  data: () => ({
    sop: [],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Pengaduan",
        disabled: true,
        href: "",
      },
      {
        text: "SOP Pengadaan",
        disabled: false,
        href: "/pengaduan/sop",
      },
    ],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getIMG(value) {
      if (value) {
        return (
          process.env.VUE_APP_API_BASE + "upload/sopPengaduanGambar/" + value
        );
      } else {
        return (
          process.env.VUE_APP_API_BASE + "upload/sopPengaduanGambar/default.svg"
        );
      }
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "sopPengaduan")
        .then((res) => {
          this.jumlah = res.data.count;
          this.sop = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showIMG(value) {
      window.open(
        process.env.VUE_APP_API_BASE + "upload/sopPengaduanGambar/" + value,
        "_blank"
      );
    },
  },
};
</script>
